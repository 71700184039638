import { UseQueryOptions, queryOptions, useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { StateSchema } from '@Schemas/domain/state'

import contentAPI from '@Services/contentAPI'

const StatesSchema = z.array(StateSchema)

const fetchStates = async () => {
  const response = await contentAPI.get('/v0/states')
  const { data } = response

  return StatesSchema.parse(data)
}

type SuccessResponse = Awaited<ReturnType<typeof fetchStates>>
type ErrorResponse = Error

type StateQueryOptions = Omit<
  UseQueryOptions<SuccessResponse, ErrorResponse, SuccessResponse, string[]>,
  'queryKey' | 'queryFn'
>

const stateQueryOptions = (options) => {
  return queryOptions({
    queryKey: ['states'],
    queryFn: fetchStates,
    ...options
  })
}

export const useStateQuery = (options?: StateQueryOptions) => {
  return useQuery(stateQueryOptions(options))
}

import { useRouter } from 'next/navigation'
import { ComponentProps } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'src/ui/button'

import { cn } from '@Shared/helpers/util'

import Icon from '@Components/icon'

type SearchProps = ComponentProps<'div'>

const Search = ({ className, ...props }: SearchProps) => {
  const router = useRouter()
  const { handleSubmit, register } = useForm()

  const submitForm = (data) => {
    const { search } = data
    if (search !== '') {
      const url = `${process.env.SEARCH_PAGE_URL}?q=${search}`
      router.push(url)
    }
  }

  return (
    <div className={cn('w-full sm:w-[500px]', className)} {...props}>
      <h2 className="mb-6 text-lg">O que você procura?</h2>
      <form className="relative" onSubmit={handleSubmit(submitForm)}>
        <input
          autoFocus
          className="box-border h-12 w-full appearance-none rounded border border-l-4 border-solid border-transparent border-l-ing-neutral-300 py-0 pl-[10px] pr-[35px] font-uol text-sm text-black outline-0 focus:border-ing-blue-400"
          {...register('search')}
          placeholder="O que você procura?"
          type="text"
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              event.preventDefault()
              submitForm({ search: event.currentTarget.value })
            }
          }}
        />
        <Button className="absolute right-3 bg-transparent p-0" type="submit">
          <Icon
            className="mr-0 size-5 cursor-pointer fill-ing-blue-400"
            src="/images/search.svg"
          />
        </Button>
      </form>
    </div>
  )
}

export default Search

'use client'

import { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'

import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

const handleTracking = (action: string, pageName) => {
  tracking.event({
    event: 'gaEventPush',
    event_category: pageName,
    event_action: action,
    event_label: ''
  })
}

const CustomCookieConsent = () => {
  const pageName = usePageName()

  const showConsentBanner =
    globalThis?.document?.location?.hostname !== 'localhost'

  useEffect(() => {
    showConsentBanner &&
      globalThis?.dataLayerGTM &&
      handleTracking('Show privacy policies', pageName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex justify-around [&_.CookieConsent]:fixed [&_.CookieConsent]:z-[1000] [&_.CookieConsent]:mb-2 [&_.CookieConsent]:flex [&_.CookieConsent]:w-[calc(100%_-_60px)] [&_.CookieConsent]:flex-col [&_.CookieConsent]:flex-wrap [&_.CookieConsent]:items-center [&_.CookieConsent]:justify-between [&_.CookieConsent]:rounded-[5px] [&_.CookieConsent]:border [&_.CookieConsent]:border-solid [&_.CookieConsent]:border-ing-blue-400 [&_.CookieConsent]:bg-[#444] [&_.CookieConsent]:p-4 [&_.CookieConsent]:text-center [&_.CookieConsent]:text-[15px] [&_.CookieConsent]:font-normal [&_.CookieConsent]:not-italic [&_.CookieConsent]:leading-5 [&_button]:mt-[10px] [&_button]:cursor-pointer [&_button]:rounded [&_button]:border [&_button]:border-solid [&_button]:border-ing-blue-400 [&_button]:bg-ing-blue-400 [&_button]:px-4 [&_button]:py-[6px] [&_button]:text-base [&_button]:font-[600] [&_button]:uppercase [&_button]:not-italic [&_button]:leading-[18px] [&_button]:tracking-[1px] [&_button]:text-white [&_button]:hover:opacity-80">
      {showConsentBanner && (
        <CookieConsent
          onAccept={() =>
            handleTracking('Click on ok - privacy policies', pageName)
          }
          cookieName="ingressoCookieConsent"
          extraCookieOptions={{ domain: `${process.env.COOKIE_DOMAIN}` }}
          buttonText="OK"
          disableStyles={true}
        >
          Utilizamos cookies essenciais e tecnologias semelhantes de acordo com
          a nossa{' '}
          <a
            className="font-bold text-ing-blue-400"
            target="_blank"
            href="https://sobreuol.noticias.uol.com.br/normas-de-seguranca-e-privacidade"
            rel="noreferrer"
          >
            Política de Privacidade
          </a>{' '}
          e, ao continuar navegando, você concorda com estas condições.
        </CookieConsent>
      )}
    </div>
  )
}

export default CustomCookieConsent

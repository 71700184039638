'use client'

import Script from 'next/script'
import { ComponentProps, useEffect } from 'react'

import { cn } from '@Shared/helpers/util'
import useAdvertiseStore from '@Shared/hooks/use-advertise-store/use-advertise-store'
import { usePageName } from '@Shared/hooks/use-page-name'

import CookieConsent from '@Components/cookie-consent'

type BasePageProps = ComponentProps<'div'>

const BaseLayout = ({ children }: BasePageProps) => {
  const currentPage = usePageName()

  const [__, setAdvertiseLoaded] = useAdvertiseStore()

  useEffect(() => {
    if (Boolean(currentPage)) {
      window.universal_variable = window.universal_variable || {}
      window.universal_variable.dfp = window.universal_variable.dfp || {}
      window.uolads = window.uolads || []
      window.universal_variable.dfp.custom_params = {
        chan: currentPage.toLowerCase()
      }
    }
  }, [currentPage])

  return (
    <>
      <Script id="GTMScript">{process.env.GA_SCRIPT}</Script>
      <Script src={process.env.UOL_DMP_URL} id="DMPScript" async />
      <Script
        src={process.env.UOL_ADS_URL}
        id="UOLAds"
        onLoad={() => {
          window.universal_variable = window.universal_variable || {}
          window.universal_variable.dfp = window.universal_variable.dfp || {}
          window.uolads = window.uolads || []
          setAdvertiseLoaded && setAdvertiseLoaded(true)
        }}
      />
      <div className={cn('mt-[50px] overflow-x-hidden md:mt-[120px]')}>
        {children}
      </div>
      <CookieConsent />
    </>
  )
}

export default BaseLayout

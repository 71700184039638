'use-client'

import { useRouter } from 'next/navigation'
import { ComponentProps } from 'react'

import { THEATER } from '@Shared/constants/page-name'
import { cn } from '@Shared/helpers/util'
import useCity from '@Shared/hooks/use-city'
import { usePageName } from '@Shared/hooks/use-page-name'

import { City } from '@Schemas/domain/state'

import tracking from '@Services/tracking'

import Icon from '@Components/icon'

type CityHistoryProps = ComponentProps<'ul'>

const CityHistory = ({ className, ...props }: CityHistoryProps) => {
  const { cityHistory, setCity } = useCity()
  const pageName = usePageName()
  const router = useRouter()

  const handleLocationTracking = (label) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: 'Location',
      event_action: 'Click on Recent City',
      event_label: label,
      event_value: '0',
      event_non_interaction: false
    })
  }

  const handleClick = (city: City) => {
    const selectedCity = cityHistory?.find(
      (previousCity) => previousCity?.urlKey === city?.urlKey
    )

    if (selectedCity) {
      handleLocationTracking(selectedCity.name)
      setCity(selectedCity)

      const url = new URL(window.location.href)

      url.searchParams.set('city', selectedCity.urlKey)

      if (pageName.toLowerCase() === THEATER) {
        url.pathname = '/'
      }

      router.push(url.href)
    }
  }

  return (
    Boolean(cityHistory.length) && (
      <ul
        className={cn('mt-[15px] flex flex-col-reverse', className)}
        {...props}
      >
        {cityHistory.map((city) => (
          <li
            className="mb-[10px] inline-flex w-full cursor-pointer leading-none text-ing-blue-400"
            key={city.id}
            onClick={() => handleClick(city)}
          >
            <Icon
              src="/images/recent.svg"
              className="[&_path]:fill-ing-blue-400 [&_svg]:h-5 [&_svg]:w-5"
            />
            <span className="ml-[5px]">{city.name}</span>
          </li>
        ))}
      </ul>
    )
  )
}

export default CityHistory

'use client'

import { ComponentProps } from 'react'

import { cn } from '@Shared/helpers/util'
import useCity from '@Shared/hooks/use-city'

import CityHistory from '@Components/user-location/city-history'
import CitySelector from '@Components/user-location/city-selector'
import GPSLocation from '@Components/user-location/gps-location'

type LocationProps = ComponentProps<'div'>

const Location = ({ className, ...props }: LocationProps) => {
  const { city } = useCity()

  return (
    <div
      className={cn('flex flex-col gap-6 md:flex-row', className)}
      {...props}
    >
      <div className="md:w-[300px]">
        <strong className="text-lg">Você está em: {city.name}</strong>
        <GPSLocation />
        <CitySelector />
      </div>
      <div className="border-t-0 border-l-ing-neutral-500 md:w-[200px] md:border-l md:border-solid md:pl-6">
        <strong className="mb-6 hidden text-lg md:block">Últimos Locais</strong>
        <CityHistory />
      </div>
    </div>
  )
}

export default Location

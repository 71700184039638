'use-client'

import { ComponentProps, useState } from 'react'
import Button from 'src/ui/button'

import { cn } from '@Shared/helpers/util'
import { usePageName } from '@Shared/hooks/use-page-name'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

const CONTROL_PANEL_URL = process.env.CONTROL_PANEL_URL

type SupportProps = ComponentProps<'div'> & {
  handleLoadHelper: () => void
}

const Support = ({ handleLoadHelper, className, ...props }: SupportProps) => {
  const [cancelOrder, setCancelOrder] = useState(false)
  const pageName = usePageName()

  const handleSupportTracking = (action) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: pageName,
      event_action: `Click in Support - ${action}`,
      event_label: '',
      event_value: '0',
      event_non_interaction: false
    })
  }

  return (
    <div className={cn('md:max-w-[700px]', className)} {...props}>
      {!cancelOrder ? (
        <div className="flex flex-col flex-wrap gap-4">
          <h2 className="text-lg md:text-xl">Quer cancelar um pedido?</h2>
          <p>
            Você mesmo pode cancelar seu pedido, sem contactar um atendente. É
            rápido e fácil!
          </p>
          <div className="flex gap-8 pt-4 max-md:flex-col">
            <Button
              className="px-2 md:px-10"
              size="sm"
              onClick={() => setCancelOrder(!cancelOrder)}
            >
              Sim, quero cancelar um pedido
            </Button>
            <Link
              className="text-ing-blue-400 no-underline"
              href="https://atendimento.ingresso.com"
              onClick={() => handleSupportTracking('Another Service')}
            >
              <Button
                size="sm"
                variant="outline"
                className="w-full px-2 md:px-10"
              >
                Não, quero outro tipo de atendimento
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <h2 className="text-lg md:text-xl">
            Sua compra foi realizada com seu usuário logado, ou foi através da
            opção {`"comprar sem cadastro"`}?
          </h2>
          <div>
            <em className="text-sm">Usuário logado:</em>
            <ul className="flex max-md:flex-col max-md:gap-4 md:[&_li:first-child]:pl-0 [&_li:last-child]:before:hidden [&_li]:relative [&_li]:px-4 [&_li]:before:absolute [&_li]:before:right-0 [&_li]:before:top-1/2 [&_li]:before:block [&_li]:before:h-3 [&_li]:before:w-5 [&_li]:before:bg-no-repeat [&_li]:before:content-[''] md:[&_li]:px-10 md:[&_li]:before:bg-[url(/images/right-arrow.png)]">
              <li>
                Acesse a página de{' '}
                <Link
                  className="text-ing-blue-400 no-underline hover:opacity-70"
                  href={`${CONTROL_PANEL_URL}/meus-pedidos`}
                >
                  Meus Pedidos
                </Link>
              </li>
              <li>
                Clique em <strong>Ver Detalhes</strong> do pedido que quer
                cancelar
              </li>
              <li>
                Clique no botão <strong>Cancelar Pedido</strong>
              </li>
            </ul>
          </div>
          <div>
            <em className="text-sm">Compra sem cadastro:</em>
            <br />
            <Button
              variant="ghost"
              size="sm"
              className="h-auto px-0"
              onClick={handleLoadHelper}
            >
              Clique aqui
            </Button>{' '}
            para abrir o assistente que vai prosseguir com o cancelamento.
            <div className="flex gap-8 pt-8 max-md:flex-col">
              <Link
                href={`${CONTROL_PANEL_URL}/meus-pedidos`}
                openExternalInSameTab
              >
                <Button
                  size="sm"
                  className="w-full px-2 md:px-10"
                  onClick={() => handleSupportTracking('Order Cancelation')}
                >
                  Comprei com meu usuário logado
                </Button>
              </Link>
              <Button
                size="sm"
                className="px-2 md:px-10"
                variant="outline"
                onClick={handleLoadHelper}
              >
                Usei a “compra sem cadastro”
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Support

import { ComponentProps } from 'react'
import Button from 'src/ui/button'

import { cn } from '@Shared/helpers/util'
import { usePageName } from '@Shared/hooks/use-page-name'
import useUserData from '@Shared/hooks/use-user-data'

import tracking from '@Services/tracking'

import Link from '@Components/Link'

const CONTROL_PANEL_URL = process.env.CONTROL_PANEL_URL

type LoginProps = ComponentProps<'div'>

const Login = ({ className, ...props }: LoginProps) => {
  const { user } = useUserData()
  const isUserLogged = Boolean(user?.token)

  const pageName = usePageName()

  const handleLoginTracking = (action) => {
    tracking.event({
      event: 'gaEventPush',
      event_category: pageName,
      event_action: `Click on My account - ${action}`,
      event_label: '',
      event_value: '0',
      event_non_interaction: false
    })
  }

  const menu = [
    { label: 'Meus Pedidos', url: `${CONTROL_PANEL_URL}/meus-pedidos` },
    { label: 'Dados Pessoais', url: `${CONTROL_PANEL_URL}/edicao-de-cadastro` },
    {
      label: 'Formas de Pagamento',
      url: `${CONTROL_PANEL_URL}/formas-de-pagamento`
    },
    { label: 'Sair da Conta', url: `${CONTROL_PANEL_URL}/logout` }
  ]

  return (
    <div className={cn('w-[250px] md:w-[500px]', className)} {...props}>
      {isUserLogged ? (
        <nav>
          <h2 className="mb-6 text-lg">Olá, {user?.name}</h2>
          <ul className="flex flex-col gap-8">
            {menu.map((item) => (
              <li
                key={item.label}
                className="text-lg last:border-t last:border-solid last:border-ing-neutral-500 last:pt-8"
              >
                <Link
                  className="text-ing-blue-400 no-underline hover:opacity-80"
                  href={item.url}
                  openExternalInSameTab={item.url.includes('logout')}
                >
                  {item?.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="right-3 w-1/2 pr-6">
            <h2 className="mb-6 text-lg leading-normal">
              Ainda não é cliente Ingresso.com?
            </h2>
            <p className="mb-8 text-base leading-5">
              Compre ingressos e combos de pipoca para ir ao cinema com
              segurança e mais comodidade!
            </p>
            <Button
              asChild
              size="sm"
              onClick={() => handleLoginTracking('Create new account')}
            >
              <Link
                href={`${CONTROL_PANEL_URL}/cadastro`}
                openExternalInSameTab
              >
                Criar uma nova conta
              </Link>
            </Button>
          </div>
          <div className="border-t-solid ml-6 mt-8 border-l-ing-neutral-500 sm:mt-0 sm:border-l sm:border-t-0 sm:border-solid sm:pl-6">
            <h2 className="mb-6 mt-5 text-lg sm:mt-0">Cliente Ingresso.com</h2>
            <p className="mb-[35px] text-[15px]">
              <Link
                className="text-ing-blue-400 no-underline"
                href={`${CONTROL_PANEL_URL}/edicao-de-cadastro`}
                openExternalInSameTab
                onClick={() => handleLoginTracking('Login')}
              >
                Entrar na minha Conta
              </Link>
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login
